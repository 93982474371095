/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */




.cd-primary-nav .cd-secondary-nav .col:first-child + .col + .col {
  border-right: none;
}

.cd-primary-nav ul.is-hidden {
  z-index: -1;
}

.cd-primary-nav .cd-secondary-nav .col ul ul .go-back {
  display: block;
}

.cd-primary-nav .cd-secondary-nav .col ul ul .go-back a {
  color: white;
}

@media screen and (min-width:1200px) {
  .cd-primary-nav .cd-secondary-nav, .cd-primary-nav .cd-nav-gallery, .cd-primary-nav .cd-nav-icons {
    top: 200px!important;
  }
}
